/* Activity Content */

.custom-activity h1, .ck-content h1 {
  @apply 
  text-xl 
  font-bold 
  text-center 
  font-sans 
  text-gray-700 
  pt-4
  px-6;
}
.custom-activity h2, .ck-content h2 {
  @apply 
  text-lg 
  font-bold 
  font-sans 
  text-secondary-600 
  pt-4
  px-6;
}
.custom-activity h3, .ck-content h3 {
  @apply 
  text-lg
  font-bold 
  font-sans 
  text-gray-700
  pt-4
  px-6;
}
.custom-activity p, .ck-content p {
  @apply 
  text-lg
  font-normal 
  text-gray-700 
  pt-4
  px-6
  leading-7;
}
.custom-activity img, .ck-content img {
  @apply 
  max-h-screen 
  mx-auto 
  px-6
  mt-4;
}

.custom-activity hr, .ck-content hr {
  @apply 
  border border-gray-100
  mt-4 mb-0
}


/* Video Stuff */

#remote-media-div video {
  @apply w-full h-full object-contain absolute bg-black ;
  transform: scaleX(-1);
}

#local-media-div video {
  @apply absolute top-0 left-0 w-full h-full object-contain bg-black rounded-xl shadow-lg p-2;
  transform: scaleX(-1);
}

/* Small screen for local user */
.twilio-local-video {
  @apply absolute right-4 bottom-4 w-2/5 pb-[30%] md:pb-[20%] md:w-1/4 xl:pb-[15%] xl:w-1/5;
}
/* Waiting room screen for local user */
.twilio-local-video-waiting {
  @apply absolute w-[70%] h-[70%] mx-auto
}
